export const aboutArticles = [
  {
    title: 'Кто мы',
    text: 'Студия Ридеамус — команда молодых профессионалов в области кинопроизводства. Делаем фильмы от идеи до постродакшена.'
  },
  {
    title: 'Что мы делаем',
    text: 'За время существования наша компания работала над созданием рекламных роликов, телепередач и проектов по заказу телеканала ТВ-3.'
  },
  {
    title: 'Как мы работаем',
    text: 'Мы стремимся сотрудничать с талантливыми и перспективными режиссерами, тщательно подбирая группу для каждого проекта.'
  },
  {
    title: 'Наша задача',
    text: 'Наша задача — создание качественных кинокартин и телевизионных фильмов, которые завоюют внимание и интерес зрителя.'
  }
];
