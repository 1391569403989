import { useStyles } from './style';

const Contacts = () => {
  const classes = useStyles();

  return (
    <div className={classes.contacts}>
      <div className={classes.mapContainer}>
        <div className={classes.map}></div>
        <div className={classes.shadow} />
        <div className={classes.mapText}>
          <span>Москва</span>
        </div>
      </div>
      <div className={classes.contactList}>
        <div className={classes.contactListItem}>
          <span>г. Москва, ул. Мосфильмовская, д. 1</span>
        </div>
        <div className={classes.contactListItem}>
          <span className={classes.phone}>+ 7 499 350 60 32</span>
        </div>
        <div className={classes.contactListItem}>
          <span>corp@rideamus.org</span>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
