import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  about: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '30px',
    margin: '34px 0 128px'
  },
  info: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '320px',
    flexGrow: 1
  }
});
