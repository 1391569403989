import { useStyles } from './style';

interface AboutArticleProps {
  title: string;
  text: string;
  last?: boolean;
}

const AboutArticle = ({ title, text, last }: AboutArticleProps) => {
  //@ts-ignore
  const classes = useStyles(last);

  return (
    <div className={classes.aboutArticleContainer}>
      <div className={classes.aboutArticle}>
        <span className={classes.title}>{title}</span>
        <span className={classes.text}>{text}</span>
      </div>
    </div>
  );
};

export default AboutArticle;
