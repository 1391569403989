import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  aboutArticleContainer: (last) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    width: '25%',
    '@media (max-width: 1359px)': {
      width: '50%'
    }
  }),
  aboutArticle: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%'
  },
  title: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '37px',
    textTransform: 'uppercase',
    color: 'white'
  },
  text: {
    marginTop: '34px',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '32px',
    color: 'white'
  },
  break: {
    flexBasis: '100%',
    height: 0
  }
});
