export const LOCALHOST = 'http://localhost:8080/';

export const customUrl = (url: string) => {
  let customUrl = url;
  if (process.env.NODE_ENV === 'development') {
    customUrl = LOCALHOST + customUrl;
  }
  return customUrl;
};

export const customFetch = async (url: string) => {
  const response = await fetch(customUrl(url));

  return response.json();
};

export const imageSrc = (imageName: string) =>
  customUrl(`images/work_examples/${imageName}`);
