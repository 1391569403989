import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  member: (last) => ({
    width: '300px',
    fontSize: '30px',
    lineHeight: '33px',
    color: 'white',
    wordSpacing: '100vw',
    marginRight: last ? 0 : '25px',
    marginTop: '50px',
    '@media (max-width: 1359px)': {
      width: '280px',
      marginRight: last ? 0 : '15px',
      '&>img': {
        width: '280px'
      }
    }
  }),
  name: {
    marginTop: '43px',
    fontWeight: 300
  },
  post: {
    marginTop: '8px',
    fontWeight: 600
  }
});
