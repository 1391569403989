import AboutArticle from './AboutArticle';
import { useStyles } from './style';
import { aboutArticles } from './fixtures';
import Partners from './Partners';

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.about}>
      <div className={classes.info}>
        {aboutArticles.map(({ title, text }, index, array) => (
          <AboutArticle
            key={index}
            title={title}
            text={text}
            last={index === array.length - 1}
          />
        ))}
      </div>
      <Partners />
    </div>
  );
};

export default About;
