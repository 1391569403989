import { createUseStyles } from 'react-jss';
import { customUrl } from '../../utils';

export const useStyles = createUseStyles({
  contacts: {
    margin: '60px 0 60px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  contactList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    fontWeight: 300,
    fontSize: '30px',
    lineHeight: '37px',
    color: 'white'
  },
  contactListItem: {
    margin: '7px 0 7px',
    textAlign: 'center'
  },
  phone: {
    '@media (max-width: 1359px)': {
      width: '400px'
    }
  },
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    width: '400px',
    position: 'relative',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
      '& span': {
        transition: 'all ease-in 0.5s',
        color: 'rgba(1, 1, 1)'
      },
      '& > $map': {
        top: 0,
        height: '400px',
        width: '400px'
      },
      '& > $shadow': {
        top: '200px',
        height: 0,
        width: 0
      }
    }
  },
  map: {
    position: 'absolute',
    top: '50px',
    height: '300px',
    width: '300px',
    borderRadius: '100%',
    backgroundPosition: 'center',
    backgroundImage: `url(${customUrl('images/map.png')})`,
    backgroundSize: '400px',
    transition: 'all ease-in 0.5s'
  },
  mapText: {
    top: '145px',
    position: 'absolute',
    fontWeight: 700,
    fontSize: '90px',
    textTransform: 'uppercase',
    color: 'white'
  },
  shadow: {
    position: 'absolute',
    top: '50px',
    height: '300px',
    width: '300px',
    borderRadius: '100%',
    backgroundColor: 'rgba(1, 1, 1, 0.4)',
    transition: 'all ease-in 0.5s'
  }
});
