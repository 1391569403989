import { useStyles } from './style';
import { customUrl } from '../../../utils';

const Partners = () => {
  const classes = useStyles();

  return (
    <div className={classes.partners}>
      <span className={classes.name}>Наши партнеры</span>
      <div className={classes.logos}>
        <div>
          <img src={customUrl('images/logo_tnt.png')} alt="ТНТ" />
        </div>
        <div>
          <img src={customUrl('images/logo_tv3.png')} alt="ТВ3" />
        </div>
        <div>
          <img src={customUrl('images/logo_sts.png')} alt="СТС" />
        </div>
        <div>
          <img
            style={{ marginRight: 0 }}
            src={customUrl('images/logo_mosfilm.png')}
            alt="Мосфильм"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
